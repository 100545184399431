<template>
  <div class="data">
    <div class="data-header">
      <span class="data-title">数据概况</span>
      <div class="data-content">
        <div class="data-item" v-for="(item,index) in severalList" :key=index>
          <span class="text">{{ item.total }}</span>
          <span class="g-num">{{ (item.num/10000).toFixed(2) }}万</span>
          <div class="added-this-week">
            <span class="text">本周新增</span>
            <i class="iconfont">&#xe643;</i>
            <span class="num">{{
              Math.floor(item.addNum / 1000) / 10 + "万"
            }}</span>
          </div>
          <div class="added-this-week">
            <span class="text">本月新增</span>
            <i class="iconfont">&#xe643;</i>
            <span class="num">{{ (item.monthNum/10000).toFixed(0) }}万</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 粉丝趋势 -->
    <div class="data-trend">
      <span class="title">粉丝趋势</span>
      <div class="content">
        <div class="data-menu">
          <div
            class="item"
            :class="{ selected: fanTabIndex === 0 }"
            @click="toggleFanTab(0)"
          >
            增量
          </div>
          <div
            class="item"
            :class="{ selected: fanTabIndex === 1 }"
            @click="toggleFanTab(1)"
          >
            总量
          </div>
        </div>
        <div id="fanTrend" :style="{ width: '100%', height: '442px' }"></div>
      </div>
    </div>

    <!-- 点赞趋势 -->
    <div class="data-trend">
      <span class="title">点赞趋势</span>
      <div class="content">
        <div class="data-menu">
          <div
            class="item"
            :class="{ selected: likeTrendIndex === 0 }"
            @click="likeTrendFunTab(0)"
          >
            增量
          </div>
          <div
            class="item"
            :class="{ selected: likeTrendIndex === 1 }"
            @click="likeTrendFunTab(1)"
          >
            总量
          </div>
        </div>
        <div id="likeTrend" :style="{ width: '100%', height: '442px' }"></div>
      </div>
    </div>

    <!-- 评论趋势 -->
    <div class="data-trend">
      <span class="title">评论趋势</span>
      <div class="content">
        <div class="data-menu">
          <div
            class="item"
            :class="{ selected: commentIndex === 0 }"
            @click="commentTrendTab(0)"
          >
            增量
          </div>
          <div
            class="item"
            :class="{ selected: commentIndex === 1 }"
            @click="commentTrendTab(1)"
          >
            总量
          </div>
        </div>
        <div
          id="commentTrend"
          :style="{ width: '100%', height: '442px' }"
        ></div>
      </div>
    </div>

    <!-- 前10作品 -->
    <!-- 评论趋势 -->
    <div class="data-trend">
      <span class="title">近期10个作品表现</span>
      <div class="content">
        <div id="topTenTrend" :style="{ width: '100%', height: '442px' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  showRankingDetail,
  showRankingTrend,
  showAuthorInfo,
  showTopTen,
} from "@/utils/apis";
export default {
  name: "DataOverviewModule",
  props: ["author_id",'is_date','date','type'],
  data() {
    return {
      // 图表
      fanTrend: null,
      likeTrend: null,
      commentTrend: null,
      toptenTrend: null,
      //数据概况
      mineInfo: [], //存放周数据
      authorinfo: [], //存放博主总粉丝等数据
      severalList: [
        {
          total: "总粉丝数",
          num: 0,
          addNum: 0,
          monthNum: 0,
        },
        {
          total: "总点赞数",
          num: 0,
          addNum: 0,
          monthNum: 0,
        },
        {
          total: "总转发数",
          num: 0,
          addNum: 0,
          monthNum: 0,
        },
        {
          total: "总评论数",
          num: 0,
          addNum: 0,
          monthNum: 0,
        },
      ],
      //粉丝趋势近7天/30天切换
      fanTabIndex: 0,
      //点赞趋势近7天/30天切换
      likeTrendIndex: 0,
      // 评论趋势近7天/30天切换
      commentIndex: 0,
      //抖音账户粉丝增量趋势数据
      FansDiffTrend: {},
      //抖音账户点赞增量趋势数据
      YeahDiffTrend: {},
      //抖音账户粉丝总量趋势数据
      FansTotalTrend: {},
      //抖音账户点赞总量趋势数据
      YeahTotalTrend: {},
      // 抖音账户评论增量趋势数据
      CommentDiffTrend: {},
      // 抖音账户评论总量趋势数据
      CommentTotalTrend: {},
      // 近期前10作品数据
      toptenTrendData: [],
    };
  },
  mounted() {
    this.getAuthorData();
    this.getAuthorInfo();
    this.getTopTenData();
    this.getDate();
    this.fanTrend = this.$echarts.init(document.getElementById("fanTrend"));
    this.likeTrend = this.$echarts.init(document.getElementById("likeTrend"));
    this.commentTrend = this.$echarts.init(
      document.getElementById("commentTrend")
    );
    this.toptenTrend = this.$echarts.init(
      document.getElementById("topTenTrend")
    );
    let resizeTimer = null;
    const self = this;
    window.addEventListener("resize", function () {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        self.fanTrend.resize();
        self.likeTrend.resize();
        self.commentTrend.resize();
        self.toptenTrend.resize();
      }, 100);
    });
  },
  methods: {
    // 播主详情-数据概览 粉丝、点赞、评论趋势
    getDate() {
      showRankingTrend(this.author_id).then((res) => {
        //粉丝趋势
        this.FansTotalTrend = res.data.total;
        this.fanTrendFun(this.FansTotalTrend);
        this.FansDiffTrend = res.data.diff;
        this.fanTrendFun(this.FansDiffTrend);
        // 点赞趋势
        this.YeahTotalTrend = res.data.total;
        this.likeTrendFun(this.YeahTotalTrend);
        this.YeahDiffTrend = res.data.diff;
        this.likeTrendFun(this.YeahDiffTrend);
        // 评论趋势
        this.CommentTotalTrend = res.data.total;
        this.commentTrendFun(this.CommentTotalTrend);
        this.CommentDiffTrend = res.data.diff;
        this.commentTrendFun(this.CommentDiffTrend);
      });
    },

    // 播主详情-数据概览 播主信息
    getAuthorInfo() {
      let param={
       userid: this.author_id,
       is_date:this.is_date,
       date:this.date,
       type:this.type
      }
      showAuthorInfo(param).then((res) => {
        this.authorinfo = res.data;
        this.severalList[0].num = this.authorinfo.follower_count;
        this.severalList[1].num = this.authorinfo.total_favorited;
        this.severalList[2].num = this.authorinfo.total_share;
        this.severalList[3].num = this.authorinfo.total_comment;
      });
    },

    // 播主详情-数据概览 数据概览
    getAuthorData() {
      showRankingDetail(this.author_id)
        .then((res) => {
          this.mineInfo = res.data;
          this.severalList[0].addNum = this.mineInfo.week.week_fans;
          this.severalList[0].monthNum = this.mineInfo.month.month_fans;
          this.severalList[1].addNum = this.mineInfo.week.week_favorited;
          this.severalList[1].monthNum = this.mineInfo.month.month_favorited;
          this.severalList[2].addNum = this.mineInfo.week.week_share;
          this.severalList[2].monthNum = this.mineInfo.month.month_share;
          this.severalList[3].addNum = this.mineInfo.week.week_comment;
          this.severalList[3].monthNum = this.mineInfo.month.month_comment;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.msg,
            duration: 1000,
          });
        });
    },

    // 近期前10作品表现
    getTopTenData() {
      showTopTen(this.author_id).then((res) => {
        this.toptenTrendData = res.data.reverse();
        this.topTenFun();
      });
    },

    //粉丝趋势近7天/30天切换
    toggleFanTab(index) {
      // console.log(index)
      this.fanTabIndex = index;
      if (index == 0) {
        this.fanTrendFun(this.FansDiffTrend); //粉丝趋势
      } else {
        this.fanTrendFun(this.FansTotalTrend); //粉丝趋势
      }
    },
    //点赞趋势近7天/30天切换
    likeTrendFunTab(index) {
      this.likeTrendIndex = index;
      if (index == 0) {
        this.likeTrendFun(this.YeahDiffTrend); //点赞趋势
      } else {
        this.likeTrendFun(this.YeahTotalTrend); //点赞趋势
      }
    },

    //评论趋势近7天/30天切换
    commentTrendTab(index) {
      this.commentIndex = index;
      if (index == 0) {
        this.commentTrendFun(this.CommentDiffTrend);
      } else {
        this.commentTrendFun(this.CommentTotalTrend);
      }
    },

    // 点赞趋势
    likeTrendFun(yeahTrendArr) {
      // 时间和数据
      let time = [],
        data = [];
      for (let i in yeahTrendArr) {
        // 保存时间和数据
        time.push(
          yeahTrendArr[i].date.substring(5, yeahTrendArr[i].date.length)
        );
        data.push(yeahTrendArr[i].total_favorited);
      }
      // console.log(time)
      // console.log(data)
      let max = Math.max(...data);
      let min = Math.min(...data);
      this.likeTrend = this.$echarts.init(document.getElementById("likeTrend"));
      this.likeTrend.setOption(
        {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          color: "#584EEE",
          title: {
            x: "center",
            y: "bottom",
            textStyle: {
              //设置主标题风格
              Color: "#333", //设置主标题字体颜色
              fontSize: 14,
              fontStyle: "normal",
            },
          },
          xAxis: {
            type: "",
            data: time,
            //设置网格线颜色
            // boundaryGap: false,
            // 隐藏刻度线
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#999",
              },
              // rotate: 40,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#999",
              },
            },
          },
          yAxis: {
            type: "value",
            name: "点赞量",
            nameTextStyle: {
              fontSize: 16,
              padding: 15,
            },
            min: min,
            max: max,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#f1f1f1",
              },
            },
            //隐藏刻度线
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            axisLabel: {
              fontSize: "14",
              marginTop: "35px",
              formatter: function (value, index) {
                if (value >= 10000) {
                  return `${value / 10000}W`;
                } else {
                  return value;
                }
              },
              show: true,
            },
          },
          series: [
            {
              name: "点赞总数",
              data: data,
              type: "line",
              lineStyle: {
                color: "#584EEE",
                width: 3,
              },
areaStyle: {
                //区域填充样式
                normal: {
                  //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                  color: new this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(88,78,238,0.9)",
                      },
                      {
                        offset: 1,
                        color: "rgba(88,78,238,0.1)",
                      },
                    ],
                    false
                  ),
                  shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                  shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                },
              },
              smooth: true,
              symbol: "none",
            },
          ],
        },
        true
      );
    },

    // 粉丝趋势
    fanTrendFun(fansTrendArry) {
      // 时间和数据
      let time = [],
        data = [];
      for (let i in fansTrendArry) {
        // 保存时间和数据
        time.push(fansTrendArry[i].date);
        data.push(fansTrendArry[i].follower_count);
      }
      let max = Math.max(...data);
      let min = Math.min(...data);
      let fanTrend = this.$echarts.init(document.getElementById("fanTrend"));
      fanTrend.setOption(
        {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          color: "#584EEE",
          title: {
            x: "center",
            y: "bottom",
            textStyle: {
              //设置主标题风格
              Color: "#333", //设置主标题字体颜色
              fontSize: 14,
              fontStyle: "normal",
            },
          },
          xAxis: {
            type: "",
            data: time,
            //设置网格线颜色
            // boundaryGap: false,
            // 隐藏刻度线
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#999",
              },
              // rotate: 40,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#999",
              },
            },
          },
          yAxis: {
            type: "value",
            name: "粉丝数",
            nameTextStyle: {
              fontSize: 16,
              padding: 15,
            },
            min: min,
            max: max,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#f1f1f1",
              },
            },
            //隐藏刻度线
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            axisLabel: {
              fontSize: "14",
              marginTop: "35px",
              formatter: function (value, index) {
                if (value >= 10000) {
                  return `${value / 10000}W`;
                } else {
                  return value;
                }
              },
              show: true,
            },
          },
          series: [
            {
              name: "粉丝总数",
              data: data,
              type: "line",
              lineStyle: {
                color: "#584EEE",
                width: 3,
              },
             areaStyle: {
                //区域填充样式
                normal: {
                  //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                  color: new this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(88,78,238,0.9)",
                      },
                      {
                        offset: 1,
                        color: "rgba(88,78,238,0.1)",
                      },
                    ],
                    false
                  ),
                  shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                  shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                },
              },
              smooth: true,
              symbol: "none",
            },
          ],
        },
        true
      );
    },

    // 评论趋势
    commentTrendFun(commentTrendArr) {
      // 时间和数据
      let time = [],
        data = [];
      for (let i in commentTrendArr) {
        // 保存时间和数据
        time.push(
          commentTrendArr[i].date.substring(5, commentTrendArr[i].date.length)
        );
        data.push(commentTrendArr[i].total_comment);
      }
      // console.log(time)
      // console.log(data)
      let max = Math.max(...data);
      let min = Math.min(...data);
      this.commentTrend = this.$echarts.init(
        document.getElementById("commentTrend")
      );
      this.commentTrend.setOption(
        {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          color: "#584EEE",
          title: {
            x: "center",
            y: "bottom",
            textStyle: {
              //设置主标题风格
              Color: "#333", //设置主标题字体颜色
              fontSize: 14,
              fontStyle: "normal",
            },
          },
          xAxis: {
            type: "",
            data: time,
            //设置网格线颜色
            // boundaryGap: false,
            // 隐藏刻度线
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#999",
              },
              // rotate: 40,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#999",
              },
            },
          },
          yAxis: {
            type: "value",
            name: "评论数",
            nameTextStyle: {
              fontSize: 16,
              padding: 15,
            },
            min: min,
            max: max,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#f1f1f1",
              },
            },
            //隐藏刻度线
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            axisLabel: {
              fontSize: "14",
              marginTop: "35px",
              formatter: "{value}",
              show: true,
            },
          },
          series: [
            {
              name: "评论总数",
              data: data,
              type: "line",
              lineStyle: {
                color: "#584EEE",
                width: 3,
              },
              areaStyle: {
                //区域填充样式
                normal: {
                  //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                  color: new this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(88,78,238,0.9)",
                      },
                      {
                        offset: 1,
                        color: "rgba(88,78,238,0.1)",
                      },
                    ],
                    false
                  ),
                  shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                  shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                },
              },
              smooth: true,
              symbol: "none",
            },
          ],
        },
        true
      );
    },

    // 近十个作品
    topTenFun() {
      // 时间和数据
      let toptenArr = this.toptenTrendData;
      let time = [],
        diggs = [], //存放点赞数
        comments = [], //存放评论数
        data = []; //存放标题
      for (let i in toptenArr) {
        // 保存时间和数据
        time.push(toptenArr[i].aweme_create_time);
        data.push(toptenArr[i].aweme_title);
        diggs.push(Number(toptenArr[i].digg_count));
        comments.push(toptenArr[i].comment_count);
      }
      let digg_max = Math.max(...diggs);
      let digg_min = Math.min(...diggs);
      let comment_max = Math.max(...comments);
      let comment_min = Math.min(...comments);
      let title = data;
      this.toptenTrend = this.$echarts.init(
        document.getElementById("topTenTrend")
      );
      this.toptenTrend.setOption(
        {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
            formatter: (e) => {
              return (
                e[0].name +
                "<br>" +
                "标题：" +
                e[0].data +
                "<br>" +
                "评论量：" +
                e[2].data +
                "<br>" +
                "点赞量：" +
                e[1].data
              );
            },
          },
          xAxis: [
            {
              type: "category",
              data: time,
              axisPointer: {
                type: "shadow",
              },
              axisLine: {
                color: "#999",
                lineStyle: {
                  color: "#999",
                },
              },
              axisLabel: {
                color: "#999",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              min: digg_min,
              max: digg_max,
              axisLabel: {
                formatter: diggs,
                color: "#999",
              },
              axisLine: {
                show: false,
              },
              axisTick: {
              show: false,
            }, 
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#f1f1f1",
                  width: 1,
                  type: "solid",
                },
              },
            },
            {
              type: "value",
              min: comment_min,
              max: comment_max,
              axisLabel: {
                formatter: comments,
                color: "#999",
              },
              axisLine: {
                show: false,
              },
              axisTick: {
              show: false,
            }, 
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#f1f1f1",
                  width: 1,
                  type: "solid",
                },
              },
            },
          ],
          series: [
            {
              type: "line",
              data: title,
            },
            {
              type: "bar",
              color: "rgb(88,78,238)",
              data: diggs,
              name: "点赞量",
            },
            {
              type: "line",
              name: "22",
              itemStyle: {
                color: "#04A8FF",
              },
              yAxisIndex: 1,
              name: "评论量",
              data: comments,
            },
          ],
        },
        true
      );
    },
  },
};
</script>

<style scoped lang="scss">
.data {
  padding: 0 30px;
  height: 100%;
  /*overflow: auto;*/
  .data-header {
    .data-title {
      font-size: 14px;
      font-weight: 400;
    }
    .data-content {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      line-height: 35px;
      .data-item {
        display: flex;
        flex-direction: column;
        .g-num {
          font-size: 24px;
          font-weight: bold;
          color: #5f55f1;
        }
        .added-this-week {
          & i {
            font-size: 14px;
            color: #ffb624;
            margin: 0 14px;
          }
          & .num {
            font-size: 14px;
            font-weight: bold;
            color: #ffb624;
          }
        }
      }
      .text {
        color: #666666;
        font-size: 14px;
      }
    }
  }

  .data-trend {
    margin-top: 20px;
  }

  .data-menu {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    .item {
      line-height: 1;
      display: inline-block;
      padding: 10px 12px;
      cursor: pointer;
      background: #f1f0fe;
      border-radius: 5px 0 0 5px;
      &:hover {
        background: #564ced;
        color: #ffffff;
      }
      &:last-of-type {
        border-radius: 0 5px 5px 0;
      }
    }

    .selected {
      background: #564ced;
      color: #ffffff;
    }
  }
}
</style>
