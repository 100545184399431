<template>
  <div class="company-contain">
    <div class="table-box">
      <!-- 高级筛选 -->
      <div class="advanced-filtering">
        <!-- 日期筛选 -->
        <el-radio-group
          v-model="dayRadio"
          style="margin-left: 20px; margin-right: 20px"
          size="small"
          @change="changDateValue"
        >
          <el-radio-button label="7">7天</el-radio-button>
          <el-radio-button label="15">15天</el-radio-button>
          <el-radio-button label="30">30天</el-radio-button>
        </el-radio-group>
        <!-- 时间筛选 -->
        <el-date-picker
          v-model="timeValue"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="medium"
          style="margin-right: 20px"
          value-format="yyyy-MM-dd"
          @change="changeTimeValue"
        >
        </el-date-picker>
        <!-- 输入筛选 -->
        <el-input
          v-model="inputValue"
          placeholder="请输入视频标题、热词或关键词"
          style="width: 280px"
          size="medium"
        ></el-input>
      </div>
      <el-table
        :data="taskList"
        class="college-table"
        style="width: 100%; margin-top: 20px"
        size="medium"
        @sort-change="changeOrder"
        :header-cell-style="{
          fontWeight: 'Regular',
          color: '#333',
          background: '#fff',
        }"
        :cell-style="{ fontSize: '12px', color: '#333' }"
      >
        <el-table-column
          prop="aweme_title"
          label="视频作品"
          align="left"
          width="700"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <div
              @click="changeToDetail(scope.row)"
              class="videoBox"
              style="display: flex; cursor: pointer"
            >
              <div class="picBox">
                <img
                  style="width: 90px; height: 120px"
                  :src="scope.row.aweme_cover"
                />
              </div>
              <div style="padding: 20px 0 10px 20px">
                <div style="font-size: 14px">{{ scope.row.aweme_title }}</div>
                <div>
                  <span>热词：</span>
                    <template v-if="scope.row.hot_words_info && scope.row.hot_words_info.length > 0">
                        <span style="margin-left: 5px" v-for="(item, index) in scope.row.hot_words_info" :key="index">{{ item }}</span>
                    </template>
                  <span v-else>无</span>

                </div>
                <div>
                  <span>发布时间：</span>
                  <span>{{ scope.row.aweme_create_time }}</span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="digg_count"
          label="点赞量"
          align="center"
          sortable
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{ scope.row.digg_count }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="comment_count"
          label="评论数"
          align="center"
          sortable
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
              scope.row.comment_count
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="share_count"
          label="转发"
          align="center"
          sortable
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{ scope.row.share_count }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="250" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              circle
              @click="changeToDetail(scope.row)"
              size="small"
            >
              <i class="iconfont">&#xe6a1;</i>
            </el-button>

            <el-button
              type="danger"
              circle
              @click="jumpToVideo(scope.row)"
              size="small"
            >
              <i class="iconfont">&#xe63a;</i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="pages-center"
      style="margin-bottom: 10px"
      :current-page="adminPages.currentPageNum"
      :page-size="adminPages.eachPageNum"
      layout="prev, pager, next, jumper"
      :total="adminPages.total"
      @current-change="adminCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
import { showVideoData } from "@/utils/apis";
import dayjs from "dayjs";
export default {
  props: ["author_id"],
  name: "HotVideo",
  data() {
    return {
      isAll: 0,
      dayRadio: 7, //天数筛选
      dayType: 0, //根据天数筛选改变数值 默认0
      timeValue: [], //获取日期数据
      inputValue: "", //获取文本框数据
      orderType: "", //需要排序的字段
      sortType:'',//排序方式
      taskList: [], //任务列表
      //分页
      adminPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
    };
  },
  mounted() {
      let end_time = dayjs().format('YYYY-MM-DD');
      let start_time = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
      this.timeValue = [start_time, end_time];
      this.getAllClass();
  },
  methods: {
    //   设置排序方式
    changeOrder(val) {
      this.orderType = val.prop.replace('_count', '');
      if (val.order === "ascending") {
        this.sortType = "asc";
      } else if (val.order === "descending") {
        this.sortType = "desc";
      } else {
        this.sortType = "";
      }
      console.log(this.sortType)
    },
    // 设置日周月，显示数据
    changDateValue(val) {
        console.log('val',val);
        let end_time = dayjs().format('YYYY-MM-DD');
        let start_time = "";
        switch (Number(val)) {
            case 7:
                start_time = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
                this.timeValue = [`${start_time}`, `${end_time}`];
                break;
            case 15:
                start_time = dayjs().subtract(14, 'day').format('YYYY-MM-DD');
                this.timeValue = [start_time, end_time];
                break;
            case 30:
                start_time = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
                this.timeValue = [start_time, end_time];
                break;
        }

    },
    // 根据时间显示数据
    changeTimeValue(val) {
      this.timeValue = val;
      console.log('val', val);
    },
    // 获取全部视频列表
    getAllClass() {
      let param = {
        userid: this.author_id, //id
        page: this.adminPages.currentPageNum,
        limit: this.adminPages.eachPageNum, //页面显示条数
      };
      if (this.timeValue && this.timeValue[0]) {
        param.start_date = this.timeValue[0]; //开始时间
      }
      if (this.timeValue && this.timeValue[1]) {
        param.end_date = this.timeValue[1]; //结束时间
      }
      if (this.orderType) {
        param.sort = this.orderType; //需要进行排序的字段
      }
      if (this.sortType) {
        param.orderby = this.sortType; //排序方式
      }
      if (this.inputValue) {
        param.keywork = this.inputValue; //输入框数据
      }
      showVideoData(param).then((res) => {
        if (res.code === 200) {
            this.taskList = res.data.list;
            this.adminPages.total = res.data.total;
        }
      });
    },
    //分页
    adminCurrentChange(val) {
      this.adminPages.currentPageNum = val;
      this.getAllClass();
    },
    //跳到详情页
    changeToDetail(row) {
      this.$router.push({
        path: "/student/hot/videoDetail",
        query: {
          aweme_id: row.aweme_id,
          author_id:this.author_id
        },
      });
    },
    //打开视频
    jumpToVideo(row) {
      window.open(row.aweme_url);
    },
  },
  watch: {
    isAll: function (val) {
      this.getAllClass();
    },
    timeValue: function (val) {
      this.getAllClass();
    },
    inputValue: function (val) {
      this.getAllClass();
    },
    dayType: function (val) {
      this.getAllClass();
    },
    sortType:function(val){
        this.getAllClass()
    },
  },
};
</script>

<style scoped lang="scss">
.company-contain {
  background-color: #ffffff;
  height: 100%;

  .head_box {
    height: 170px;
  }

  .table-box {
    .college-table {
      padding: 0 20px;
    }

    ::v-deep .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      background-color: none !important;
    }
    ::v-deep .el-table::before {
      height: none;
    }
  }

  .divide {
    padding: 10px 20px 0 20px;
  }

  .filter-box {
    padding-top: 10px;

    .box1 {
      padding: 0 20px 10px 20px;
      height: 40px;
      display: flex;

      .filter-box1 {
        span {
          margin-top: 6px;
          line-height: 32px;
          display: inline-block;
          width: 66px;
          height: 32px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
