<template>
  <div class="promotion-container">
    <div class="table-box">
      <!-- 高级筛选 -->
      <div class="advanced-filtering" style="margin-bottom: 30px">
        <!-- 日期筛选 -->
        <el-radio-group
          v-model="dayRadio"
          style="margin-right: 20px"
          size="small"
          @change="changDateValue"
        >
          <el-radio-button :label="item.value" v-for="(item, key) in date_range">{{item.label}}</el-radio-button>
        </el-radio-group>
        <!-- 时间筛选 -->
        <el-date-picker
          v-model="timeValue"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="medium"
          style="margin-right: 20px"
          value-format="yyyy-MM-dd"
          @change="changeTimeValue"
        >
        </el-date-picker>
        <!-- 输入筛选 -->
        <el-input
          v-model="inputValue"
          placeholder="请输入要搜索的内容"
          style="width: 260px"
          size="medium"
        ></el-input>
      </div>
      <!-- 商品品牌分类 -->
      <div style="margin-bottom: 20px" class="goodsList">
        <!-- 商品分类 -->
          <div class="category-list">
              <div class="category-title">选择分类</div>
              <div class="category-lists">
                <div class="category-item" v-for="(item, key) in categoryList" :key="key" @click="setCateIndex(item.name)">
                    <span class="cate-name" :class="[item.name === categoayOne ? 'active' : '']">{{item.name}}({{item.value}})</span>
                </div>
              </div>
          </div>
        <!-- 品牌分类 -->
          <div class="category-list">
              <div class="category-title">选择品牌</div>
              <div class="category-lists">
                  <div class="category-item" v-for="(brand, key) in brandListAll" :key="key" @click="setBrandName(brand.id)">
                      <span class="cate-name" :class="[brand.id === brand_no ? 'active' : '']">{{brand.name}}({{brand.value}})</span>
                  </div>
              </div>
          </div>
      </div>
      <!-- 商品列表 -->
      <el-table
        :data="taskList"
        class="college-table"
        style="width: 100%; margin-top: 20px"
        size="medium"
        @sort-change="changeOrder"
        :header-cell-style="{
          fontWeight: 'Regular',
          color: '#333',
          background: '#fff',
        }"
        :cell-style="{ fontSize: '12px', color: '#333' }"
      >
        <!-- 商品 -->
        <el-table-column
          prop="title"
          label="商品"
          align="left"
          width="500"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <div style="display: flex">
              <div class="picBox">
                <img
                  style="width: 100px; height: 100px"
                  :src="scope.row.product_image"
                />
              </div>
              <div style="padding: 20px 0 10px 20px">
                <div style="font-size: 14px">{{ scope.row.product_title }}</div>
                <div style="font-size: 14px; font-weight: bold">
                  <span>￥</span>
                  <span>{{ scope.row.price }}</span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 佣金比例 -->
        <el-table-column
          prop="brokerage"
          label="佣金比例"
          align="center"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{ scope.row.commission_rate }}%</span>
          </template>
        </el-table-column>
        <!-- 日销量 -->
        <el-table-column
          prop="day_order_count"
          label="销量"
          align="center"
          sortable
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">
                {{scope.row.volume}}
            </span>
          </template>
        </el-table-column>
        <!-- 月销量 -->
        <el-table-column
          prop="month_order_count"
          label="销售额"
          align="center"
          sortable
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
                scope.row.amount
            }}</span>
          </template>
        </el-table-column>
        <!-- 30天转化率 -->
        <el-table-column
          prop="month_conversion"
          label="视频"
          align="center"
          sortable
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
              scope.row.aweme_count
            }}</span>
          </template>
        </el-table-column>
        <!-- 操作 -->
<!--        <el-table-column label="操作" width="250" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button-->
<!--              type="primary"-->
<!--              circle-->
<!--              @click="changeToDetail(scope.row)"-->
<!--              size="small"-->
<!--            >-->
<!--              <i class="iconfont">&#xe6a1;</i>-->
<!--            </el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
    </div>
    <!-- 分页 -->
    <el-pagination
      class="pages-center"
      style="margin-bottom: 10px"
      :current-page="adminPages.currentPageNum"
      :page-size="adminPages.eachPageNum"
      layout="prev, pager, next, jumper"
      :total="adminPages.total"
      @current-change="adminCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
import { showGoodsList, showGoodsCategory } from "@/utils/apis";
import dayjs from "dayjs";
export default {
  props: ["author_id"],
  data() {
    return {
      dayRadio: 7, //天数筛选
      dayType: 0, //根据天数筛选改变数值 默认0
      timeValue: [], //获取日期数据
      inputValue: "", //获取文本框数据
      orderType: "", //需要排序的字段
      sortType: "", //排序方式
      categoayOne: "全部", //一级分类
      categoayTwo: "", //二级分类
        brand_no: 0,
      taskList: [], //任务列表
      categoryList: [{id: 0, name: '全部', value: 0}], //分类列表
      goodsListAll: {}, //分类列表提取的全部分类
      brandListAll: {}, //品牌列表提取的全部分类
      //分页
      adminPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
        date_range: [
            {label: '7天', value: 7},
            {label: '15天', value: 15},
            {label: '30天', value: 30},
        ],
    };
  },
  mounted() {
      let end_time = dayjs().format('YYYY-MM-DD');
      let start_time = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
      this.timeValue = [start_time, end_time];
    this.getAllClass();
    this.getGoodsCategory();
  },
  methods: {
      formatW(val) {
          let sale = Number(val);
          if (sale < 10000) {
              return sale.toFixed(2);
          } else {
              let sale_acount = sale / 10000;
              return sale_acount.toFixed(2) + 'w';
          }
      },
      setCateIndex(cate_name) {
            this.categoayOne = cate_name.trim();
            this.getAllClass();
      },
      setBrandName(brand_name) {
            this.brand_no = brand_name.trim();
            this.getAllClass();
      },
    //   设置排序方式
    changeOrder(val) {
      this.orderType = val.prop;
      if (val.order === "ascending") {
        this.sortType = "asc";
      } else if (val.order === "descending") {
        this.sortType = "desc";
      } else {
        this.sortType = "";
      }
      console.log(this.sortType);
    },
    // 设置日周月，显示数据
    changDateValue(val) {
        console.log('val', val);
    },
    // 根据时间显示数据
    changeTimeValue(val) {
      this.timeValue = val;
    },
    //获取商品列表
    getAllClass() {
      let param = {
        userid: this.author_id, //id
        page: this.adminPages.currentPageNum,
        limit: this.adminPages.eachPageNum, //页面显示条数
      };
      if (this.timeValue && this.timeValue[0]) {
        param.start_date = this.timeValue[0]; //开始时间
      }
      if (this.timeValue && this.timeValue[1]) {
        param.end_date = this.timeValue[1]; //结束时间
      }
      if (this.inputValue) {
        param.keywork = this.inputValue; //输入框数据
      }
      if (this.categoayOne && this.categoayOne !== '全部') {
        param.big_category = this.categoayOne; //一级分类
      }
      if (this.brand_no) {
          param.brand_code = this.brand_no;
      }
      if (this.categoayTwo) {
        param.first = this.categoayTwo; //二级分类
      }
      if (this.dayType) {
        param.type = this.dayType; //日周月
      }
      if (this.sortType) {
        param.sort = this.sortType; //排序方式
      }
      if (this.orderType) {
        param.order = this.orderType; //需要进行排序的字段
      }
      showGoodsList(param)
        .then((res) => {
            if (res.code === 200) {
                this.taskList = res.data.list;
                let all = {id: 0, name: '全部', value: 0};
                res.data.category.map((item, index) => {
                    all.value += item.value;
                });
                this.categoryList = [all].concat(res.data.category);
                //品牌
                let brand = {id: 0, name: '全部', value: 0};
                res.data.brand.map((item) => {
                    brand.value += item.value;
                });
                this.brandListAll = [brand].concat(res.data.brand);

            } else {
                this.$message.warning(res.msg);
            }

        })
        .catch((err) => {
          console.log(err);
        });
    },

    //     获取商品分类
    getGoodsCategory() {
      let param = {
        userid: this.author_id,
      };
      showGoodsCategory(param).then((res) => {
        if (res.data.length === 0) {
          return false;
        }
        this.categoryList = res.data;
        this.goodsListAll = res.data.big.shift();
        this.brandListAll = res.data.brand.shift();
      });
    },

    //分页
    adminCurrentChange(val) {
      this.adminPages.currentPageNum = val;
      this.getAllClass();
    },
    //跳到详情页
    changeToDetail(row) {
      this.$router.push({
        path: "/student/dy/goodDetail",
        query: {
          id: row.product_id,
        },
      });
    },
  },
  watch: {
    timeValue: function (val) {
      this.getAllClass();
    },
    inputValue: function (val) {
      this.getAllClass();
    },
    dayType: function (val) {
      this.getAllClass();
    },
    sortType: function (val) {
      this.getAllClass();
    },
  },
};
</script>

<style lang="scss" scoped>
.promotion-container {
  .table-box {
    padding: 0 20px;
    .college-table {
      padding: 0 20px;
    }
    .goodsList {
      display: flex;
      flex-direction: column;
      .active {
        cursor: pointer;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }
}
.category-list {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.category-lists {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
}
.category-list {
    .category-title {
        font-weight: bold;
        color: #999;
        font-size: 14px;
        margin-right: 10px;
    }
    .category-lists {
        .category-item {
            margin: 0 10px 0px 0;
            padding: 3px 10px 3px 0;
            cursor: pointer;
            .cate-name {
                color: #666;
                &.active {
                    color: #409EFF;
                }

            }
        }
    }
}

</style>